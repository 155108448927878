import React from 'react';
import MainPageSearch from '@components/SearchWithCTA/MainPageSearch';
import FAQsCardViewerDesktop from '@components/FAQsCardViewerDesktop';
import Section from '@components/Section';
import { navigate } from 'gatsby';
import useCurrentPath from '@helpers/hooks/useCurrentPath';
import { stringify } from 'query-string';
import { encodeQueryParams, StringParam, ArrayParam } from 'use-query-params';
import {
  SEARCH_FAQs_HEADER,
  SEARCH_FAQs_SUB_HEADER,
  SEARCH_FAQs_PLACEHOLDER,
  URL_RESULTS_PATH,
} from './constants';
import ContactUs from './ContactUs';
import FAQsCardViewerMobile from '@components/FAQsCardViewerMobile';

const Viewer = ({ data }) => {
  const { flexSearchOptions, searchSuggestions, employerFaqs } = data;
  const currentUrlPath = useCurrentPath();
  const handleEmployerFaqsQuery = (response) => {
    const { query, results } = response;
    const encodedQuery = encodeQueryParams(
      { query: StringParam, search: ArrayParam },
      { query, search: results }
    );
    const urlPath = `${currentUrlPath}${URL_RESULTS_PATH}`;
    const urlQueryParams = `?${stringify(encodedQuery)}`;
    navigate(`${urlPath}${urlQueryParams}`);
  };
  return (
    <div className="jobseeker-faqs-section">
      <MainPageSearch
        header={SEARCH_FAQs_HEADER}
        subHeader={SEARCH_FAQs_SUB_HEADER}
        searchPlaceholder={SEARCH_FAQs_PLACEHOLDER}
        flexSearchOptions={flexSearchOptions}
        searchSuggestions={searchSuggestions}
        onQuery={handleEmployerFaqsQuery}
      />
      <Section className="faq-viewer-section">
        <FAQsCardViewerDesktop faqsContents={employerFaqs} />
        <FAQsCardViewerMobile faqsContents={employerFaqs} />
      </Section>
      <ContactUs />
    </div>
  );
};

export default Viewer;
