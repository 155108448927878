import React from 'react';
import Layout from '@components/Layout/LayoutTemplates/EmployersLayout';
import SEO from '@components/SEO';
import EmployersFAQsModule from '@modules/EmployersFAQs/Main';

const EmployersFAQs = () => (
  <Layout isReleased={true} isMobileComingSoon={false}>
    <SEO title="FAQs" description="FAQs" />
    <div className="FAQs-section">
      <EmployersFAQsModule />
    </div>
  </Layout>
);

export default EmployersFAQs;
